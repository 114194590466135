<template>
    <div class="messageCore-main">
        <div class="main-list">
            <van-list
                v-model="loading"
                :finished="finished"
                
                :finished-text='messageList.length==0 ? "" : $t("message.nomore")'
                @load="onLoad"
                >
                <div class="item-container" v-for="(item,index) in messageList" :key="index" @click="goDetail(item)">
                    <p class="time-txt">{{getLocalMinutesTime(item.msgCdt)}}</p>
                    <div class="container-sect">
                        <div class="sect-head">
                            <p class="left-title">
                                <!-- <img src="@/assets/img/pageSet-icon3.png" alt=""> -->
                                <img :src="item.typeIcoUrl" alt="">
                                <span class="txt">{{item.typeName}} <i class="after" v-if="item.hasOpened == 0"></i></span>
                            </p>
                            <p class="detail-btn">
                                <span class="txt">{{$t("message.seeDetails")}}</span>
                                <van-icon  name="arrow" />
                            </p>
                        </div>
                        <div class="container-txt">
                            <p class="message-txt">{{item.msgTitle}}</p>
                            <p class="message-desc">{{item.msgSummary}}</p>
                        </div>
                    </div>
                </div>
            </van-list>
            <div class="messageEmpty" v-if="messageList == 0">
                <img src="@/assets/img/message-empty.png" alt="">
                <p class="txt">{{$t("remiind.nomessage")}}</p>
                <p class="desc">{{$t("remiind.tips")}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { messagecore } from "@/api/index";
import { getLocalMinutesTime } from "@/utils/date";
export default {
    data () {
        return {
            pageSize:10,
            pageNum:1,
            messageList:[],
            getLocalMinutesTime,
            loading: false,
            finished: false,
        }
    },
    async created(){
        // const toast = this.$Toast.loading({
        //     duration: 0, // 持续展示 toast
        //     forbidClick: true,
        //     message: this.$t("message.loadingTxt")
        // });
        // // await this.getMessageData();
        // toast.close();
        document.title = this.$t("remiind.name")
    },
    methods:{
        getMessageData(){
            messagecore({
                pageSize:this.pageSize,
                pageNum:this.pageNum
            }).then(res=>{
                console.log(res.data.data.data);
                this.loading = false;
                if(res.data.errorCode == 0){
                    if(this.pageNum == 1){
                        this.messageList = res.data.data.data;
                    }else{
                        res.data.data.data.map(item=>{
                            this.messageList.push(item)
                        })
                    }
                    if(res.data.data.data.length < this.pageSize){
                        this.finished = true;
                    }
                }
            })
        },
        goDetail(row){
            if(row.msgUrl){
                window.location.href = row.msgUrl;
            }
        },
        onLoad(){
            if(!this.finished){
                this.loading = true;
                this.getMessageData();
                // this.loading = false;
                this.pageNum ++;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.messageCore-main{
    min-height: 100%;
    background: #F5F6FA;
    padding:0 0.6rem 0.6rem 0.6rem;
    box-sizing: border-box;
    .main-list{
        overflow: hidden;
        .item-container{
            margin-top: 1rem;
            .time-txt{
                font-size: 0.48rem;
                font-family: Helvetica;
                color: #A8A8AB;
                width: max-content;
                padding: 0.14rem 0.6rem;
                margin: 0 auto;
                background: #ECEEF3;
                border-radius: 0.4rem;
            }
            .container-sect{
                margin-top: 0.5rem;
                background: #fff;
                padding: 0 0.5rem;
                border-radius: 0.24rem;
                .sect-head{
                    height: 1.8rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 0.02rem solid #ECEEF3;
                    .left-title{
                        display: flex;
                        align-items: center;
                        img{
                            margin-right: 0.3rem;
                            width: 0.96rem;
                            height: 0.96rem;
                        }
                        .txt{
                            font-size: 0.64rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            position: relative;
                            .after{
                                position: absolute;
                                width: 0.34rem;
                                height: 0.34rem;
                                background: #FF1A2A;
                                border-radius: 50%;
                                top: 0.1rem;
                                right: -0.4rem;
                            }
                        }
                    }
                    .detail-btn{
                        display: flex;
                        align-items: center;
                        .txt{
                            font-size: 0.52rem;
                            font-family: Helvetica;
                            color: #666666;
                        }
                        /deep/ .van-icon {
                            font-size: 0.64rem;
                        }
                     }
                }
                .container-txt{
                    overflow: hidden;
                    .message-txt{
                        font-size: 0.64rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                        line-height: 0.8rem;
                        margin: 0.46rem 0 0 0;
                    }
                    .message-desc{
                        font-size: 0.52rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        margin: 0.32rem 0 0.6rem 0;
                    }
                    p{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;

                    }
                }
            }
        }
        /deep/ .van-loading {
            height: 2rem;
            line-height: 2rem;
            .van-loading__text,.van-loading__spinner {
                font-size: 0.6rem;
            }
        }
    }
    .messageEmpty{
        height: 97vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        img{
            width: 3.2rem;
            height: auto;
            margin-bottom: 0.8rem;
        }
        .txt{
            font-size: 0.64rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
        }
        .desc{
            font-size: 0.52rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
    }
}
</style>